import react, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import { observer } from 'mobx-react-lite'
import { styled } from '@mui/material/styles';

import { serviceData } from '../Mobx/status'
import { servicePicker, editBookingData } from 'src/Mobx/editState';
import { Typography, Grid } from '@mui/material';


export default observer((original) => {
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const originalBookingServices = original.original

    useEffect(() => {
        servicePicker.clearServiceArray()
        originalBookingServices.map(x =>
            servicePicker.addService(x)
        )
    }, [])

    const resetService = () => {
        // setOpen(false);
        servicePicker.clearServiceArray()
        servicePicker.resetService(originalBookingServices)
        console.log(servicePicker.services)
    }

    const saveData = () => {
        setOpen(false);
        editBookingData.setService(servicePicker.services)
    }

    const handleClose = () => {
        setOpen(false);
    };

    const ListItem = styled('li')(({ theme }) => ({
        margin: theme.spacing(0.5),
    }));


    const ServicePicker = () => {
        const [value, setValue] = useState(null)
        const [inputValue, setInputValue] = useState('')

        return (
            <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={servicePicker.getFilteredList(serviceData.serviceList)}
                filterOptions={(options, state) => options}
                getOptionLabel={(option) => option.serviceName}
                value={value}
                sx={{ width: "auto" }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue)
                }}
                renderInput={(params) => <TextField {...params} label="服務" />}
                onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                        servicePicker.addService(inputValue)
                        setInputValue("")
                    }
                }}
                ListboxProps={{ style: { maxHeight: 170 } }}
            />
        );
    }

    return (
        <div>
            <Button variant="outlined" onClick={handleClickOpen} style={{ width: '100%' }} size="large">
                {/* {servicePicker.services.map((x, i) =>
                    <Chip
                        label={x}
                        key={i}
                        style={{ margin: 5 }}
                    />
                )} */}
                更改預約服務
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                fullWidth
                style={{ minWidth: 350 }}
            >
                <DialogTitle id="alert-dialog-title">
                    選擇預約服務
                </DialogTitle>
                <DialogContent style={{ height: '50vh' }}>
                    <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center" style={{ textAlign: 'center' }}>
                        <Grid item xs={12}>
                            <Paper
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexWrap: 'wrap',
                                    listStyle: 'none',
                                    p: 0.5,
                                    m: 0,
                                }}
                                style={{ marginTop: 15, marginBottom: 25 }}
                                component="ul"
                            >
                                {servicePicker.services.length > 0 ?
                                    servicePicker.services.map((data, i) => {
                                        return (
                                            <ListItem key={i}>
                                                <Chip
                                                    color="primary"
                                                    variant="outlined"
                                                    label={data}
                                                    onDelete={() => servicePicker.removeService(data)}
                                                    style={{ margin: '15px 5px' }}
                                                />
                                            </ListItem>
                                        );
                                    })
                                    :
                                    <Button disabled>沒有已選服務</Button>
                                }
                            </Paper>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <ServicePicker />
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => resetService()}>重設</Button>
                    <Button onClick={saveData} autoFocus>
                        完成
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
})