import react, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { Grid, Paper } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';

import StaffPicker from './EditStaffPicker'

import ServiceAutoComplete from './ServiceAutoComplete';

import { getArr, getDateTime, getToken, addZero, ampm, getMonthName, monthNameToIndex, withComma, getWeekDayByDate } from '../utils'
import { createBookingData, bookingData, generalState, globalStatus, serviceData, staffData, deleteBooking } from '../Mobx/status';

// import ServicePicker from './ServicePicker'
// import SpecialistPicker from './SpecialistPicker'

import { useHistory } from 'react-router-dom/';

import { observer } from "mobx-react-lite"
import { loginState } from 'src/Mobx/login';
import { DeleteBooking, GetBookings, AddBooking, RestoreEditRecords, GetEditHistoryLength, AddEditRecord } from 'src/API/apis';
import { editBookingData, editRecord, pickerData, previousRecord, servicePicker, staffPicker, targetBooking } from 'src/Mobx/editState';

export default observer(() => {
    const booking = targetBooking.getBooking()
    const [open, setOpen] = useState(false);
    const [fullWidth, setFullWidth] = useState(true);
    const [maxWidth, setMaxWidth] = useState('sm');
    const [loading, setLoading] = useState(false)
    const [serviceName, setServiceName] = useState("")
    const [name, setName] = useState("")

    useEffect(() => {
        staffPicker.addStaff(booking.specialist.filter(x => x !== ""))
    }, [])

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        pickerData.resetAll()
        servicePicker.clearServiceArray()
        editBookingData.clearService()
    };

    const getBookingDate = () => {
        const dateArr = booking.date.match(/\d+/g)

        const year = `${dateArr[0]}`,
            month = `${+dateArr[1]}`,
            day = `${+dateArr[2]}`

        return { month, day }
    }

    const getBookingTimg = () => {
        const dateArr = booking.bookingTime.match(/\d+/g)

        const hour = +dateArr[0],
            min = +dateArr[1]

        return { hour, min }
    }

    const SearchBody = () => {
        const [min, setMin] = useState(getBookingTimg().min)
        const [hour, setHour] = useState(getBookingTimg().hour)
        const [packageID, setID] = useState(booking.packageID)
        const [month, setMonth] = useState(getBookingDate().month)
        const [day, setDay] = useState(getBookingDate().day)
        const [charge, setCharge] = useState(booking.charge)

        const MonthPicker = () => {
            const handleChange = (event) => {
                setMonth(event.target.value);
            };

            let monthRange = getArr(12)

            return (
                <FormControl fullWidth>
                    <InputLabel id="hourPickerLabel">月</InputLabel>
                    <Select
                        labelId="hourPickerLabel"
                        id="hourPicker"
                        value={month}
                        label="Age"
                        onChange={handleChange}
                    >
                        {monthRange.map((x, i) =>
                            <MenuItem key={i} value={i + 1}>{getMonthName(i)}</MenuItem>
                        )}
                    </Select>
                </FormControl>
            );
        }

        const DayPicker = () => {
            const handleChange = (event) => {
                setDay(event.target.value);
            };

            const daysInMonth = [31, 28, 30, 31, 30, 31, 30, 31, 30, 31, 30, 31]

            let daysRange = getArr(daysInMonth[month - 1])

            return (
                <FormControl fullWidth>
                    <InputLabel id="hourPickerLabel">日</InputLabel>
                    <Select
                        labelId="hourPickerLabel"
                        id="hourPicker"
                        value={day}
                        disabled={month === ""}
                        label="Age"
                        onChange={handleChange}
                    >
                        {daysRange.map((x, i) =>
                            <MenuItem key={i} value={i + 1}>{i + 1}</MenuItem>
                        )}
                    </Select>
                </FormControl>
            );
        }

        const BookingDatePicker = () => {
            return (
                <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center">
                    <Grid item xs={6}>
                        <MonthPicker />
                    </Grid>
                    <Grid item xs={6}>
                        <DayPicker />
                    </Grid>
                </Grid>
            )
        }

        const HourPicker = () => {
            const handleChange = (event) => {
                setHour(event.target.value);
                event.target.value === 22 && setMin(0)
            };

            let hourRange = getArr(24)

            return (
                <FormControl fullWidth>
                    <InputLabel id="hourPickerLabel">時</InputLabel>
                    <Select
                        labelId="hourPickerLabel"
                        id="hourPicker"
                        value={hour}
                        label="Age"
                        onChange={handleChange}
                    >
                        {hourRange.map((x, i) =>
                            i > 7 && i < 22 && <MenuItem key={i} value={i + 1}>{i + 1}</MenuItem>
                        )}
                    </Select>
                </FormControl>
            );
        }

        const MinutePicker = () => {

            const handleChange = (event) => {
                setMin(event.target.value);
            };

            let minRange = getArr(60)

            return (
                <FormControl fullWidth>
                    <InputLabel id="hourPickerLabel">分</InputLabel>
                    <Select
                        labelId="hourPickerLabel"
                        id="hourPicker"
                        value={min}
                        label="Age"
                        onChange={handleChange}
                    >
                        <MenuItem key={"00"} value={0}>00</MenuItem>
                        {hour !== 22 &&
                            minRange.map((x, i) =>
                                (i + 1) % 5 === 0 && i + 1 !== 60 && <MenuItem key={i} value={i + 1}>{addZero(i + 1)}</MenuItem>
                            )
                        }
                    </Select>
                </FormControl>
            );
        }

        const Timepicker = () => {
            return (
                <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center">
                    <Grid item xs={6}>
                        <HourPicker />
                    </Grid>
                    <Grid item xs={6}>
                        <MinutePicker />
                    </Grid>
                </Grid>
            )
        }


        const onEdit = () => {
            setLoading(true)

            const months = ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"]
            const newDate = new Date()

            let id = booking.listID

            let data = {
                bookingID: booking.bookingID,
                date: `2022-${addZero(month)}-${addZero(day)}`,
                packageID: packageID,
                bookingTime: `${addZero(hour)}:${addZero(min)} ${ampm(hour)}`,
                weekDay: "_", //getWeekDayByDate(`2022-${month}-${day}`)
                service: servicePicker.getSelectedServices(),
                charge: charge,
                specialist: staffPicker.getSelectedStaff(),
                monthCategory: months[month - 1],
                yearCategory: newDate.getFullYear(),
                createdBy: loginState.user,
                createDate: getDateTime("date"),
                createTime: getDateTime("time")
            }

            EditBooking(deleteBooking.getBookings(), data)
            

            // GetEditHistoryLength(booking.bookingID)
            //     .then(() => {
            //         let recordData = {
            //             date: getDateTime("date"),
            //             time: getDateTime("time"),
            //             bookingID: booking.bookingID,
            //             editBy: loginState.user,
            //             editCount: +(previousRecord.recordCount + 1),
            //             currentRecord: [
            //                 {
            //                     date: data.date ?? "",
            //                     packageID: data.packageID ?? "",
            //                     bookingTime: data.bookingTime ?? "",
            //                     charge: data.charge ?? "",
            //                     service: data.service ?? [],
            //                     specialist: data.specialist ?? []
            //                 }
            //             ]
            //         }

            //         EditBooking(id, data)
            //             .then(() => {
            //                 servicePicker.clearServiceArray()
            //                 editBookingData.clearService()
            //                 AddEditRecord(recordData)
            //                     .then(() => {
            //                         globalStatus.setBookingLoading(false)
            //                         setLoading(false)
            //                     })
            //             })
            //     })

            // editRecord.addRecord()


        }

        const EditBooking = (id, body) => {
            DeleteBooking(id)
            .then(() =>
                AddBooking(body)
                    .then(() =>
                        GetBookings()
                    )
            )
        }

        return (
            <>
                <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center" style={{ textAlign: 'center' }}>
                    <Grid item xs={10} style={{ marginTop: 20 }}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '25ch' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <Grid container direction="row" justifyContent="center" alignItems="center">
                                <Grid item xs={12} style={{ position: 'relative', right: 10 }}>
                                    <TextField
                                        error={false}
                                        id="outlined-error"
                                        label="套票號碼"
                                        placeholder="3962, 阿蚊, 605朋友"
                                        defaultValue={booking.packageID}
                                        value={packageID}
                                        style={{ width: '100%' }}
                                        onChange={(e) => setID(e.target.value)}
                                    />
                                </Grid>

                                <Grid item xs={12} style={{ position: 'relative', right: 6 }}>
                                    <div style={{ marginBottom: 30, marginTop: 30, position: 'relative', left: 3 }}>
                                        <BookingDatePicker />
                                    </div>
                                    <div style={{ marginBottom: 30, marginTop: 30, position: 'relative', left: 3 }}>
                                        <Timepicker />
                                    </div>
                                    <div>
                                        <ServiceAutoComplete original={booking.service} />
                                    </div>
                                    <TextField
                                        error={false}
                                        id="outlined-error"
                                        label="價錢"
                                        placeholder="800"
                                        style={{ width: '100%', position: 'relative', right: 6, marginTop: 30 }}
                                        type="number"
                                        defaultValue={booking.charge}
                                        onChange={(e) => setCharge(e.target.value)}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    $
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                    <div style={{ marginTop: 20, marginBottom: 20 }}>
                                        <StaffPicker />
                                    </div>
                                </Grid>
                            </Grid>
                            <div style={{ marginTop: 20 }}>
                                <Button
                                    variant="contained"
                                    size="large"
                                    style={{ width: '100%', marginTop: 25, marginBottom: 35, background: '#EDC2BD' }}
                                    onClick={() => onEdit()}
                                // disabled={shoudDisable()}
                                >
                                    {loading ? <CircularProgress style={{ color: '#fff' }} size={25} /> : "確定"}
                                </Button>
                            </div>
                        </Box>
                    </Grid>
                </Grid>
            </>
        );
    }

    return (
        <>
            <IconButton onClick={handleClickOpen} disabled={targetBooking.shouldDisable()}>
                <EditIcon />
            </IconButton>
            <Dialog
                fullWidth={fullWidth}
                maxWidth="lg"
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>修改預約</DialogTitle>
                <DialogContent>
                    <SearchBody />
                    <Button onClick={handleClose} style={{ width: '100%', marginBottom: 15 }}>取消</Button>
                </DialogContent>
            </Dialog>
        </>
    );
})
