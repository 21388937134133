import fetch from "./fetch"
import { bookingData, globalStatus, staffData, serviceData, deleteBooking } from "../Mobx/status"
import { addZero, jsonDecoder, getToken } from '../utils'
import { loginState } from "../Mobx/login"
import { monthlyState } from "../Mobx/reportState"
import { useHistory } from "react-router-dom"
import { editRecord, previousRecord } from "src/Mobx/editState"

export function OriginCheck() {
    return fetch("originCheck")
        .then(res =>
            console.log(res)    
        )
}

export function AddBooking(body) {
    globalStatus.setBookingLoading(true)
    monthlyState.setPageLoading(true)
    return fetch('addBooking', {
        method: 'post',
        body: body
    })
        .then(res => {
            bookingData.updateData(res)
            globalStatus.setBookingLoading(false)
            monthlyState.setPageLoading(false)
            return res
        })
        .catch(err => console.log(err))
}

export function GetBookings() {
    globalStatus.setBookingLoading(true)
    return fetch("getBookings")
        .then(res => {
            console.log(res)
            let result = res.map(x => {
                return {
                    id: x.id,
                    bookingID: x.bookingID,
                    date: x.date,
                    weekDay: x.weekDay,
                    packageID: x.packageID,
                    bookingTime: x.bookingTime,
                    service: x.service,
                    charge: x.charge,
                    specialist: x.specialist,
                    monthCategory: x.monthCategory,
                    yearCategory: x.yearCategory,
                    createdBy: x.createdBy,
                    createDate: x.createDate,
                    createTime: x.createTime,
                }
            })

            let sortedList = result.sort(function (a, b) {
                return parseFloat(Date.parse(b.date)) - parseFloat(Date.parse(a.date));
            });

            bookingData.updateData(sortedList)

            globalStatus.setBookingLoading(false)

            return res
        })
}

export function RestoreEditRecords(bookingID) {
    return fetch("main", `editHistory?bookingID=${bookingID}`)
        .then(res => {
            previousRecord.restoreRecords(res)
        })
}

export function AddEditRecord(body) {
    return fetch("main", "editHistory", {
        method: 'post',
        body: body
    })
        .then(res => {
            // previousRecord.restoreRecords(res)
            // console.log(JSON.stringify(previousRecord.records))
        })
}

export function GetEditHistoryLength(bookingID) {
    return fetch("main", `editHistory?bookingID=${bookingID}`)
        .then(res => {
            previousRecord.getRecordCount(res.length)
        })
}

export function DeleteBooking(id) {
    globalStatus.setBookingLoading(true)
    return fetch("deleteBooking", {
        method: 'post',
        body: id
    })
        .then(res => {
            globalStatus.setBookingLoading(false)
            // bookingData.updateData(res)
            return res
        })
}

export function GetStaffList() {
    return fetch("getStaffs")
        .then(res => {
            // let result = res.map(x => {
            //     return {
            //         staffID: x.staffID,
            //         staffName: x.staffName,
            //         bookingsCount: x.bookingsCount,
            //         authLevel: x.authLevel
            //     }
            // })
            console.log(res)

            staffData.updateStaffList(res)
            return res
        })
}

export function GetServiceList() {
    return fetch("getServices")
        .then(res => {
            // let result = jsonDecoder(res).map(x => {
            //     return {
            //         serviceID: x.serviceID,
            //         serviceName: x.serviceName,
            //         price: x.price,
            //         createdBy: x.createdBy
            //     }
            // })

            serviceData.updateServiceList(res)
            return res
        })
}

export function Login(data) {
    return fetch("login", {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    })
        .then((res) => {
            // let result = jsonDecoder(res).map(x => {
            //     return {
            //         username: x.username,
            //         userID: x.userID,
            //         password: x.password,
            //         authLevel: x.authLevel
            //     }
            // })

            // let result = {
            //     username: res.username,
            //     userID: res.userID,
            //     password: res.password,
            //     authLevel: res.authLevel
            // }

            let len = 1 // result.filter(x => x.username === data.username && x.password === `${((+data.password) * 157359)}`).length
            let authLevel = 3 // result.filter(x => x.username === data.username)[0].authLevel

            if (len > 0) {
                loginState.setLogin(len > 0)
                loginState.failToLogin(false)
                loginState.toLogin("Ad", 3)
                localStorage.setItem("t", {
                    token: getToken(),
                    time: Date.now() + (1000 * 60 * 60)
                })

            } else {
                loginState.failToLogin(true)
            }

            return null
        })
}