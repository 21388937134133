import react, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Appbar from './Appbar'
import { bookingData, globalStatus } from '../Mobx/status'
import { deleteBooking, selectedBooking } from '../Mobx/status';
import { AddBooking, DeleteBooking, GetBookings } from '../API/apis'
import TodaysBooking from './TodaysBooking'

import { observer } from 'mobx-react-lite'

import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { getWeekDayByDate, withComma } from 'src/utils';

import EditBooking from './EditBooking'
import EditHistory from './EditHistory';
import FilterBooking from './FilterBooking'
import { targetBooking } from 'src/Mobx/editState';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: 'packageID',
        numeric: true,
        disablePadding: false,
        label: '套票號碼',
        width: 150,
    },
    {
        id: 'date',
        numeric: false,
        disablePadding: true,
        label: '預約日期',
        width: 250,
    },
    {
        id: 'bookingTime',
        numeric: true,
        disablePadding: false,
        label: '預約時間',
        width: 150,
    },
    {
        id: 'service',
        numeric: true,
        disablePadding: false,
        label: '預約服務',
        width: 150,
    },
    // {
    //     id: 'charge',
    //     numeric: true,
    //     disablePadding: false,
    //     label: '價錢',
    //     width: 150,
    // },
    {
        id: 'specialist',
        numeric: true,
        disablePadding: false,
        label: '負責同事',
        width: 150,
    },
    {
        id: 'createdBy',
        numeric: true,
        disablePadding: false,
        label: '預約同事',
        width: 150,
    },
    {
        id: 'createDate',
        numeric: true,
        disablePadding: false,
        label: '預約確定日期',
        width: 150,
    },
    // {
    //     id: 'createTime',
    //     numeric: true,
    //     disablePadding: false,
    //     label: '預約確定時間',
    //     width: 150,
    // }
];

function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount || globalStatus.allBookingAllClicked}
                        onChange={() => {
                            globalStatus.setAllBookingAllClicked()
                        }}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align="left"
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={{ width: `${headCell.width} !important`, whiteSpace: "nowrap" }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
    const { numSelected } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <>
                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                    >
                        所有預約
                    </Typography>


                </>
            )}

            {numSelected > 0 ? (
                <>
                    {/* <EditHistory /> */}
                    <EditBooking />
                    <Tooltip title="Delete">
                        <DeleteAlert />
                    </Tooltip>
                </>
            ) : (
                <>
                    <TextField
                        placeholder="日期"
                        value={globalStatus.allBookingFilterDate}
                        size="small"
                        style={{
                            width: '100%',
                            marginRight: 15
                        }}
                        onChange={(e) => {
                            globalStatus.setAllBookingFilterDate(e.target.value)
                        }}
                        InputProps={{
                            startAdornment: <InputAdornment position="start"></InputAdornment>,
                        }}

                    />
                    <FilterBooking />
                </>
            )}

        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

const FilterMenu = () => {
    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <FilterListIcon />
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            console.log("enter")
                        }
                    }}
                >
                    <TextField
                        placeholder="日期"
                        value={globalStatus.allBookingFilterDate}
                        onChange={(e) => {
                            globalStatus.setAllBookingFilterDate(e.target.value)
                        }}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">2022 -</InputAdornment>,
                        }}
                    />
                </MenuItem>
            </Menu>
        </div>
    );
}


const DeleteAlert = () => {
    const [deleteAlert, setDeleteAlert] = useState(false);

    const onDelete = () => {
        console.log(JSON.stringify(selectedBooking.id))
        DeleteBooking(selectedBooking.id)
            .then(() =>
                GetBookings()
            )

        setDeleteAlert(false);
    }

    return (
        <div>
            <IconButton onClick={() => setDeleteAlert(true)}>
                <DeleteIcon />
            </IconButton>
            <Dialog
                open={deleteAlert}
                onClose={() => setDeleteAlert(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                innerRef={null}
            >
                <DialogTitle id="alert-dialog-title">
                    刪除預約
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" style={{ width: "100%", marginTop: 25, marginBottom: 25, textAlign: 'center', fontSize: 24 }}>
                        確定刪除所選預約？
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteAlert(false)}>取消</Button>
                    <Button onClick={onDelete} autoFocus style={{ color: "#C3302A" }}>
                        確定
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default observer(() => {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('calories');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [dense, setDense] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = bookingData.bookings.map((n) => n.bookingID);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (name, index, bookingID) => {
        let nameWithIndex = `${name}_${index}`
        const selectedIndex = selected.indexOf(nameWithIndex);
        let newSelected = [];

        // targetBooking.setBooking(+`${page}${index}`)

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, nameWithIndex);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        targetBooking.updateBookingCount(newSelected.length)

        if (newSelected.length === 1) {
            targetBooking.setBooking(bookingID)
        }
        deleteBooking.setBookingID(newSelected)
        selectedBooking.setSelected(name)

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - bookingData.bookings.length) : 0;

    return (
        <Box style={{ width: '100%', paddingBottom: 25 }}>
            <EnhancedTableToolbar numSelected={selected.length} />
            <TableContainer>
                <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size={dense ? 'small' : 'medium'}
                >
                    <EnhancedTableHead
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={bookingData.bookings.length}
                    />
                    <TableBody>
                        {stableSort(bookingData.allBooingAdvancedFilter(globalStatus.allBookingFilterDate), getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .filter(x => x.packageID !== "Testing" || x.packageID !== "Test" )
                            .map((row, index) => {
                                const isItemSelected = isSelected(`${row.bookingID}_${index}`);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                const tableCells = [
                                    { value: row.packageID, align: "left", width: 100 },
                                    { value: `${row.date} (${getWeekDayByDate(row.date)})`, align: "left", width: 150 },
                                    { value: row.bookingTime, align: "left", width: 100 },
                                    { value: row.service, align: "left", width: 100 },
                                    // { value: withComma(row.charge), align: "left", width: 100 },
                                    { value: row.specialist, align: "left", width: 100 },
                                    { value: row.createdBy, align: "left", width: 100 },
                                    { value: row.createDate, align: "left", width: 100 },
                                    // { value: row.createTime, align: "left", width: 100 },
                                ]

                                return (
                                    <Tooltip title={`套票號碼: ${row.packageID}`} placement="top" arrow>
                                        <TableRow
                                            hover
                                            onClick={(event) => handleClick(row.bookingID, index, row.bookingID)}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={`${row.bookingID}_${index}`}
                                            selected={isItemSelected}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    color="primary"
                                                    checked={isItemSelected || globalStatus.allBookingAllClicked}
                                                    inputProps={{
                                                        'aria-labelledby': labelId,
                                                    }}
                                                />
                                            </TableCell>
                                            {tableCells.map((x, index) =>
                                                // index === 0 ?
                                                    <TableCell align={x.align} key={index} style={{ whiteSpace: 'nowrap', textTransform: index !== 2 ? "capitalize" : "normal" }} id={labelId}>{x.value}</TableCell>
                                                    // :
                                                    // <TableCell align={x.align} key={index} style={{ whiteSpace: 'nowrap', textTransform: index !== 2 ? "capitalize" : "normal" }}>{index === 4 ? `$ ${x.value}` : x.value}</TableCell>
                                            )}
                                        </TableRow>
                                    </Tooltip>
                                );
                            })}
                        {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: (dense ? 33 : 53) * emptyRows,
                                }}
                            >
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                rowsPerPageOptions={[10]}
                component="div"
                count={bookingData.bookings.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            {/* <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label="Dense padding"
            /> */}

        </Box>
    );
})
