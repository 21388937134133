import react, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import { bookingData, staffData, filterAllBookings } from 'src/Mobx/status';

import FilterListIcon from '@mui/icons-material/FilterList';

import { observer } from 'mobx-react-lite'

export default observer(() => {
    const [open, setOpen] = useState(false);
    const [packageID, setPackageID] = useState("");
    const [bookingDate, setBookingDate] = useState("");
    const [charge, setCharge] = useState("");
    const [staff, setStaff] = useState("");
    const staffList = staffData.staffList.map(x => x.staffName)

    const handleClickOpen = () => {
        setOpen(true);
        filterAllBookings.clear()
    };

    const handleClose = () => {
        setOpen(false);
    };

    const inputArray = [
        { placeholder: "套票號碼", value: packageID, func: 0 },
        { placeholder: "預約日期", value: bookingDate, func: 1 },
        // { placeholder: "預約服務", value: service, func: 2 },
        { placeholder: "價錢", value: charge, func: 3 }
    ]

    const collectData = (index, value) => {
        console.log(index, value)
        if (index === 0) {
            setPackageID(value)
        } else if (index === 1) {
            setBookingDate(value)
        }
        else {
            setCharge(value)
        }
    }

    const onFilter = () => {
        filterAllBookings.setPackageID(packageID)
        filterAllBookings.setDate(bookingDate)
        filterAllBookings.setCharge(charge)
        filterAllBookings.setStaff(staff)

        handleClose()

    }

    return (
        <div>
            {filterAllBookings.isFiltering() ?
                <IconButton onClick={() => filterAllBookings.clear()}>
                    <CleaningServicesIcon style={{ color: '#E8A197' }} />
                </IconButton>
                :
                <IconButton variant="outlined" onClick={handleClickOpen}>
                    <FilterListIcon style={{ color: '#E8A197' }} />
                </IconButton>
            }
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth
                maxWidth="md"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    過濾預約
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        {/* <Grid item xs={12} style={{ textAlign: 'center' }}>
                            <FormControl>
                                <FormLabel id="demo-row-radio-buttons-group-label" style={{ color: '#444', textAlign: 'left' }}>負責同事</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={staff}
                                    onChange={(e) => {
                                        setStaff(e.target.value)
                                    }}
                                >
                                    {staffList.map((x, i) =>
                                        <FormControlLabel value={x} control={<Radio style={{ color: '#E8A197' }} />} style={{ color: '#333' }} key={i} label={x} />
                                    )}
                                </RadioGroup>
                            </FormControl>
                        </Grid> */}

                        {inputArray.map((x, i) =>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    placeholder={x.placeholder}
                                    size="small"
                                    value={x.value}
                                    style={{ width: '100%' }}
                                    // InputProps={{
                                    //     startAdornment: <InputAdornment position="start">2022 -</InputAdornment>,
                                    // }}
                                    onChange={(e) => collectData(i, e.target.value)}

                                />
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>取消</Button>
                    <Button onClick={onFilter} autoFocus>
                        過濾預約
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
})
