import React from "react";
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { Suspense } from 'react'
import 'rsuite/dist/styles/rsuite-default.css'

import LoginPage from './Components/Login/LoginPage'
import HomePage from "./Components/Home"
import CreateBooking from "./Components/CreateBooking"
import SearchBooking from './Components/SearchBooking'
import Reports from './Components/Reports'
import Management from './Components/Management'

import { loginState } from './Mobx/login'

import { observer } from 'mobx-react-lite'

const LoginExpired = (ogDestination) => {
  // var token = localStorage.getItem("t")
  // if(Date.now() > token.expiry) {
  //   loginState.setLogin(false)
  //   localStorage.removeItem("t")
  //   return <LoginPage />
  // } 
  // return ogDestination

  return loginState.isLogedIn ?
    ogDestination
    :
    // ogDestination 
    <LoginPage />
}

export default observer(() => {
  return (
    <BrowserRouter>
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>

          <Route path="/" exact>
            <LoginPage />
          </Route>

          <Route path="/home">
            {LoginExpired(<HomePage />)}
          </Route>

          <Route path="/createBooking">
            {LoginExpired(<CreateBooking />)}
          </Route>

          <Route path="/searchBooking">
            {LoginExpired(<SearchBooking />)}
          </Route>

          <Route path="/reports">
            {LoginExpired(<Reports />)}
          </Route>

          <Route path="/management">
            {LoginExpired(<Management />)}
          </Route>

        </Switch>
      </Suspense>
    </BrowserRouter>
  );
})