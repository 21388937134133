import react, { useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import ReloadIcon from '@mui/icons-material/Replay';

import { useHistory } from 'react-router-dom'
import Drawer from "./Netvigator/Drawer"
import { loginState } from 'src/Mobx/login';
import { bookingData, generalState } from 'src/Mobx/status';
import { GetBookings, GetServiceList, GetStaffList } from 'src/API/apis';

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

export default function SearchAppBar() {
    const history = useHistory()
    const [searchVal, setSearchVal] = useState("")

    return (
        <Box>
            <AppBar position="static" style={{ background: '#E7A197' }}>
                <Toolbar>
                    <Drawer />
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                    >
                        Mingsau 預約系統
                    </Typography>
                    <Search>
                        <SearchIconWrapper>
                            <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder="搜尋..."
                            inputProps={{ 'aria-label': 'search' }}
                            onChange={(e) => {
                                setSearchVal(e.target.value)


                            }}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    generalState.setSearchBookingSearchFromAppBar(true)
                                    bookingData.bookingSearch(
                                        searchVal, "", "", "", ""
                                    )
                                    generalState.setSearchBookingSearchBody(false)
                                    history.push("/searchBooking")
                                    console.log("enter")
                                }
                            }}
                        />
                    </Search>
                    <Typography style={{ marginLeft: 15, textTransform: "capitalize" }}>
                        {loginState.user}
                    </Typography>
                    <div
                        style={{ position: 'relative', top: 3, marginLeft: 8 }}
                        onClick={() => {
                            GetBookings()
                            GetStaffList()
                            GetServiceList()
                        }}
                    >
                        <ReloadIcon style={{ cursor: 'pointer' }} />
                    </div>
                </Toolbar>
            </AppBar>
        </Box>
    );
}
