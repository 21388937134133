import axios from 'axios'
import { createBookingData } from 'src/Mobx/status'

const host = "https://61e5234f595afe00176e5346.mockapi.io/"
const resources = "https://61e897637ced4a00172ff62f.mockapi.io/"
const dev = "http://localhost:5000/"
const realTime = "https://thejamesyip.com/mingsau/"

// interface fetchProps {
//     (url: string, option?: { method: 'get' | 'post' | 'delete'; body?: any }): Promise<any>;
// }

const fetch = async (url, { method, body } = { method: 'get' }) => {
    const mainFetch = async () => {

        const header = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }

        const dest = realTime //des === "py" ? dev : des === "resources" ? resources : host

        if (method === 'post') {
            const res = await axios.post(dest + url, body, header)
            return res.data
        }
        if (method == "delete") {
            const res = await axios.delete(dest + url, header)
            return res.data
        }
        const res = await axios.get(dest + url, header)

        return res.data
    }
    try {
        const res = await mainFetch()
        return res
    }
    catch (err) {
        console.log(err)
    }
}

export default fetch