import react, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Grid, Paper } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { getArr, getDateTime, getToken, addZero, ampm, getMonthName, monthNameToIndex, withComma } from '../utils'
import { createBookingData, bookingData, generalState } from '../Mobx/status';
import { useHistory } from 'react-router-dom/';

import { AddBooking, GetStaffList, GetServiceList } from '../API/apis';

import { observer } from 'mobx-react-lite'

import Appbar from './Appbar'
import ServicePicker from './ServicePicker'
import SpecialistPicker from './SpecialistPicker'


export default observer(() => {
    const [searching, setSearching] = useState(false)

    useEffect(() => {
        if (!generalState.searchBookingSearchFromAppBar) {
            bookingData.clearFilterList()
            generalState.setSearchBookingSearchBody(true)
        }
    }, [generalState.searchBookingSearchFromAppBar])

    const SearchBody = () => {
        const newDate = new Date()
        const history = useHistory()
        const [min, setMin] = useState("");
        const [hour, setHour] = useState("")
        const [packageID, setID] = useState("")
        const [month, setMonth] = useState("")
        const [day, setDay] = useState("")
        const [charge, setCharge] = useState(0)

        const MonthPicker = () => {
            const handleChange = (event) => {
                setMonth(event.target.value);
            };

            let monthRange = getArr(12)

            return (
                <FormControl fullWidth>
                    <InputLabel id="hourPickerLabel">月</InputLabel>
                    <Select
                        labelId="hourPickerLabel"
                        id="hourPicker"
                        value={month}
                        label="Age"
                        onChange={handleChange}
                    >
                        {monthRange.map((x, i) =>
                            i <= (newDate.getMonth() + 1) + 1 && i >= (newDate.getMonth() + 1) - 1 &&
                            <MenuItem value={i + 1}>{getMonthName(i)}</MenuItem>
                        )}
                    </Select>
                </FormControl>
            );
        }

        const DayPicker = () => {

            const handleChange = (event) => {
                setDay(event.target.value);
            };

            const daysInMonth = [31, 28, 30, 31, 30, 31, 30, 31, 30, 31, 30, 31]

            let daysRange = getArr(daysInMonth[month - 1])

            return (
                <FormControl fullWidth>
                    <InputLabel id="hourPickerLabel">日</InputLabel>
                    <Select
                        labelId="hourPickerLabel"
                        id="hourPicker"
                        value={day}
                        disabled={month === ""}
                        label="Age"
                        onChange={handleChange}
                    >
                        {daysRange.map((x, i) =>
                            i >= newDate.getDate() - 1 && <MenuItem value={i + 1}>{i + 1}</MenuItem>
                        )}
                    </Select>
                </FormControl>
            );
        }

        const BookingDatePicker = () => {
            return (
                <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center">
                    <Grid item xs={6}>
                        <MonthPicker />
                    </Grid>
                    <Grid item xs={6}>
                        <DayPicker />
                    </Grid>
                </Grid>
            )
        }

        const onSearch = () => {
            setSearching(true)
            bookingData.bookingSearch(
                packageID,
                month !== "" || day !== "" ? "" : `2023-${addZero(month)}-${addZero(day)}`,
                createBookingData.service,
                charge,
                createBookingData.specialist
            )

            generalState.setSearchBookingSearchBody(false)
            setSearching(false)
        }

        const shoudDisable = () => {
            let condiSt = month === "" || day === "" || packageID == "" || min === ""
            let condiSec = createBookingData.service.length === 0 || createBookingData.specialist.length === 0 || charge === 1

            let result = condiSt || condiSec

            return result
        }

        return (
            <>
                <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center" style={{ textAlign: 'center' }}>
                    <Grid item xs={10} style={{ marginTop: 20 }}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '25ch' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <Grid container direction="row" justifyContent="center" alignItems="center">
                                <Grid item xs={12} style={{ position: 'relative', right: 6 }}>
                                    <TextField
                                        error={false}
                                        id="outlined-error"
                                        label="套票號碼"
                                        placeholder="3962, 阿蚊, 605朋友"
                                        style={{ width: '100%' }}
                                        onChange={(e) => setID(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <div style={{ marginTop: 20 }}>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography style={{ color: '#666666' }}>進階搜尋</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div style={{ marginBottom: 30 }}>
                                            <BookingDatePicker />
                                        </div>
                                        <div>
                                            <ServicePicker />
                                        </div>
                                        <TextField
                                            error={false}
                                            id="outlined-error"
                                            label="價錢"
                                            placeholder="800"
                                            style={{ width: '100%', position: 'relative', right: 6, marginTop: 30 }}
                                            type="number"
                                            onChange={(e) => setCharge(e.target.value)}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        $
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                        <div style={{ marginTop: 20, marginBottom: 20 }}>
                                            <SpecialistPicker />
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div style={{ marginTop: 20 }}>
                                <Button
                                    variant="contained"
                                    size="large"
                                    style={{ width: '100%', marginTop: 25, marginBottom: 35, background: '#EDC2BD' }}
                                    onClick={() => onSearch()}
                                // disabled={shoudDisable()}
                                >
                                    {searching ? <CircularProgress style={{ color: '#fff' }} size={25} /> : "搜尋"}
                                </Button>
                            </div>
                        </Box>
                    </Grid>
                </Grid>
            </>
        );
    }

    const ResultBody = () => {
        const styles = { marginRight: 5, color: "#ccc", fontSize: 9, fontStyle: "italic" }

        return (
            <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center" style={{ textAlign: 'center', marginTop: 35, marginBottom: 35 }}>
                {bookingData.searchedBookings.map((x, index) =>
                    <Grid item xs={10}>
                        <Paper style={{ padding: '10px 10px' }}>
                            <div>
                                <TextField
                                    error={false}
                                    id="outlined-error"
                                    label="套票號碼"
                                    value={x.packageID}
                                    style={{ width: '91%', marginTop: 20, marginBottom: 20, textAlignLast: 'center' }}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </div>
                            <div>
                                <TextField
                                    error={false}
                                    id="outlined-error"
                                    label="預約日期"
                                    value={x.date}
                                    style={{ width: '44%', marginTop: 20, marginBottom: 20, marginRight: 15 }}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                                <TextField
                                    error={false}
                                    id="outlined-error"
                                    label="預約時間"
                                    value={x.bookingTime}
                                    style={{ width: '44%', marginTop: 20, marginBottom: 20 }}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </div>
                            <div>
                                <TextField
                                    error={false}
                                    id="outlined-error"
                                    label="預約服務"
                                    value={x.service}
                                    style={{ width: '91%', marginTop: 20, marginBottom: 20 }}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </div>
                            <div>
                                <TextField
                                    error={false}
                                    id="outlined-error"
                                    label="價錢"
                                    value={withComma(x.charge)}
                                    style={{ width: '91%', marginTop: 20, marginBottom: 20 }}
                                    InputProps={{
                                        readOnly: true,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                $
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </div>
                            <div>
                                <TextField
                                    error={false}
                                    id="outlined-error"
                                    label="負責同事"
                                    value={x.specialist}
                                    style={{ width: '91%', marginTop: 20, marginBottom: 20 }}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </div>
                            <div>
                                <span style={styles}>預約同事: {x.createdBy}</span>
                                <span style={styles}>預約確定日期: {x.createDate}</span>
                                <span style={styles}>預約確定時間: {x.createTime}</span>
                            </div>
                        </Paper>
                    </Grid>
                )}
            </Grid>
        )
    }
    return (
        <div style={{ height: '100vh' }}>
            <Appbar />
            <Accordion expanded={generalState.searchBookingSearchBody}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    onClick={() => generalState.setSearchBookingSearchBody(!generalState.searchBookingSearchBody)}
                >
                    <Typography>預約搜尋</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <SearchBody />
                </AccordionDetails>
            </Accordion>
            <ResultBody />
        </div>
    )
})