import react, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Skeleton from '@mui/material/Skeleton';

import IconButton from '@mui/material/IconButton';
import { observer } from "mobx-react-lite"
import EditHistoryIcon from '@mui/icons-material/SimCard';
import { targetBooking } from 'src/Mobx/editState';
import { GetEditHistoryLength, RestoreEditRecords } from 'src/API/apis';
import { Paper } from '@material-ui/core';

import { previousRecord } from '../Mobx/editState'
import { globalStatus } from 'src/Mobx/status';
import { getArr, withComma } from 'src/utils';

export default observer(() => {
    const booking = targetBooking.getBooking()
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);

        globalStatus.setHistoryLoading(true)
        console.log(booking.bookingID, booking.packageID)
        RestoreEditRecords(booking.bookingID)
            .then(() => {
                globalStatus.setHistoryLoading(false)
            })
    };

    const handleClose = () => {
        previousRecord.clear()
        setOpen(false);
    };

    let data = previousRecord.records

    const tableHeads = [
        "版本", "套裝號碼", "預約日期", "預約時間", "預約服務",
        "價錢", "負責同事", "修改同事", "修改日期", "修改時間"
    ]

    return (
        <>
            <IconButton onClick={handleClickOpen} disabled={targetBooking.shouldDisable()}>
                <EditHistoryIcon />
            </IconButton>
            <Dialog
                fullWidth
                maxWidth="lg"
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>修改紀錄</DialogTitle>
                <DialogContent>
                    {/* <HistoryBody /> */}
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    {tableHeads.map((x, i) =>
                                        <TableCell key={i} style={{ whiteSpace: "nowrap" }}>{x}</TableCell>
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    globalStatus.historyLoading ?
                                        <TableRow key="loading">
                                            <TableCell colSpan={tableHeads.length}>
                                                {getArr(3).map((x, i) => <Skeleton animation="wave" style={{ marginBottom: 10 }} />)}
                                            </TableCell>
                                        </TableRow>
                                        :
                                        data.map((x, i) => {
                                            return (
                                                data.length === 1 ? 
                                                <TableRow key="noEditHistoryKey">
                                                    <TableCell colSpan={tableHeads.length} style={{textAlign: 'center'}}>
                                                        <Button disabled>
                                                            沒有修改紀錄
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                                    :
                                                    <TableRow key={i} hover>
                                                        <TableCell component="th" scope="row" style={{ whiteSpace: "nowrap" }}>
                                                            {x.editCount}
                                                        </TableCell>
                                                        {x.currentRecord.map((res, resI) =>
                                                            <>
                                                                <TableCell component="th" scope="row" style={{ whiteSpace: "nowrap" }}>
                                                                    {res.packageID}
                                                                </TableCell>

                                                                <TableCell component="th" scope="row" style={{ whiteSpace: "nowrap" }}>
                                                                    {res.date}
                                                                </TableCell>

                                                                <TableCell component="th" scope="row" style={{ whiteSpace: "nowrap" }}>
                                                                    {res.bookingTime}
                                                                </TableCell>

                                                                <TableCell component="th" scope="row" style={{ whiteSpace: "nowrap" }}>
                                                                    {res.service.map(se => se).join(", ")}
                                                                </TableCell>

                                                                <TableCell component="th" scope="row" style={{ whiteSpace: "nowrap" }}>
                                                                    {`$ ${withComma(res.charge)}`}
                                                                </TableCell>

                                                                <TableCell component="th" scope="row" style={{ whiteSpace: "nowrap" }}>
                                                                    {res.specialist.map(sp => sp).join(", ")}
                                                                </TableCell>
                                                            </>
                                                        )}
                                                        <TableCell component="th" scope="row" style={{ whiteSpace: "nowrap", textTransform: "capitalize" }}>
                                                            {x.editBy}
                                                        </TableCell>

                                                        <TableCell component="th" scope="row" style={{ whiteSpace: "nowrap" }}>
                                                            {x.date}
                                                        </TableCell>

                                                        <TableCell component="th" scope="row" style={{ whiteSpace: "nowrap" }}>
                                                            {x.time}
                                                        </TableCell>
                                                    </TableRow>
                                            )
                                        })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </>
    );
})
