import { types } from 'mobx-state-tree'
import { bookingData } from './status'

export const pickerData = types
    .model({
        services: types.array(types.string),
        otherServices: types.array(types.string),
        staffs: types.array(types.string)
    })
    .actions(self => ({
        setStaff(val) {
            // console.log(JSON.stringify(val))
            self.staffs = val
        },
        setService(val) {
            self.services = val
        },
        setOtherService(val) {
            self.otherServices = val
            // console.log(val)
        },
        clearOthers() {
            self.otherServices.clear()

            // console.log("...", JSON.stringify(self.otherServices))
        },
        getCombinedServices() {
            let index = pickerData.services.findIndex(x => x === "其他")
            let result = index !== -1 ? pickerData.services.splice(index, 1) : pickerData.services
            return result
        },
        resetAll() {
            self.services.clear()
            self.otherServices.clear()
            self.staffs.clear()
        }
    }))
    .create({
        services: [],
        otherServices: [],
        staffs: []
    })

export const targetBooking = types
    .model({
        bookingCount: types.number,
        listID: types.string,
        bookingID: types.string,
        date: types.string,
        packageID: types.string,
        bookingTime: types.string,
        service: types.array(types.string),
        charge: types.string,
        specialist: types.array(types.string),
        monthCategory: types.string
    })
    .actions(self => ({
        setBooking(bookingID) {
            let i  = bookingData.bookings.findIndex(x => x.bookingID === bookingID)
            let data = bookingData.bookings[i]

            self.listID = data.listID
            self.bookingID = data.bookingID
            self.date = data.date
            self.packageID = data.packageID
            self.bookingTime = data.bookingTime
            self.charge = data.charge

            self.service.clear()
            self.specialist.clear()

            data.service.split(",").map(x =>
                !self.service.includes(x) && self.service.push(x)
            )
            data.specialist.split(",").map(x =>
                !self.specialist.includes(x) && self.specialist.push(x)
            )
            self.monthCategory = data.monthCategory

        },
        updateBookingCount(len) {
            self.bookingCount = len
        }
    }))
    .views(self => ({
        getBooking() {
            let data = {
                listID: self.listID,
                bookingID: self.bookingID,
                date: self.date,
                packageID: self.packageID,
                bookingTime: self.bookingTime,
                service: self.service,
                charge: self.charge,
                specialist: self.specialist,
                monthCategory: self.monthCategory
            }

            return data
        },
        shouldDisable() {
            return self.bookingCount > 1
        }
    }))
    .create({
        bookingCount: 0,
        listID: "",
        bookingID: "",
        date: "",
        packageID: "",
        bookingTime: "",
        service: [],
        charge: "",
        specialist: [],
        monthCategory: ""
    })

export const editBookingData = types
    .model({
        bookingID: types.string,
        date: types.string,
        weekDay: types.string,
        packageID: types.string,
        bookingTime: types.string,
        service: types.array(types.string),
        charge: types.string,
        specialist: types.string,
        monthCategory: types.string,
        createdBy: types.string,
        createDate: types.string,
        createTime: types.string
    })
    .actions(self => ({
        setService(data) {
            self.clearService()

            // console.log(JSON.stringify(self.service))
            data.map(x =>
                !self.service.includes(x) && self.service.push(x)
            )
        },
        clearService() {
            self.service.clear()
        }
    }))
    .create({
        bookingID: "",
        date: "",
        weekDay: "",
        packageID: "",
        bookingTime: "",
        service: [],
        charge: "",
        specialist: "",
        monthCategory: "",
        createdBy: "",
        createDate: "",
        createTime: ""
    })

export const servicePicker = types
    .model({
        services: types.array(types.string),
        staffs: types.array(types.string)
    })
    .actions(self => ({
        addService(val) {
            if (!self.services.includes(val)) {
                self.services.push(val)
            }
        },
        removeService(data) {
            let index = self.services.findIndex(x => x === data)
            self.services.splice(index, 1)
            // console.log(index)
        },
        resetService(val) {
            self.services.clear()

            val.map(x =>
                self.services.push(x)
            )
        },
        clearServiceArray() {
            self.services.clear()
        },
        addStaff(val) {
            if (!self.staffs.includes(val)) {
                self.services.push(val)
            }
        },
        removeStaff(data) {
            let index = self.staffs.findIndex(x => x === data)
            self.staffs.splice(index, 1)
            // console.log(index)
        },
        resetStaff(val) {
            self.services.clear()

            val.map(x =>
                self.staffs.push(x)
            )
        },
        clearStaffArray() {
            self.staffs.clear()
        }
    }))
    .views(self => ({
        getSelectedServices() {
            return self.services.map(x => x.trim())
        },
        getFilteredList(list) {
            let result = list.filter(x => !self.services.includes(x.serviceName))

            return result
        }
    }))
    .create({
        services: []
    })

export const staffPicker = types
    .model({
        staffs: types.array(types.string)
    })
    .actions(self => ({
        addStaff(val) {
            self.staffs.clear()
            val.map(x => {
                self.staffs.push(x)
            })

            // console.log(JSON.stringify(val))
        },
        removeStaff(data) {
            let index = self.staffs.findIndex(x => x === data)
            self.staffs.splice(index, 1)
            // console.log(index)
        },
        resetStaff(val) {
            self.staffs.clear()

            val.map(x =>
                self.staffs.push(x)
            )
        },
        clearStaffArray() {
            self.staffs.clear()
        }
    }))
    .views(self => ({
        getOriginalStaff() {
            return targetBooking.specialist
        },
        getSelectedStaff() {
            return self.staffs.map(x => x.trim())
        },
        getFilteredList(list) {
            let result = list.filter(x => !self.staffs.includes(x.serviceName))

            return result
        }
    }))
    .create({
        staffs: []
    })

const recordStructure = types
    .model({
        date: types.string,
        packageID: types.string,
        bookingTime: types.string,
        charge: types.string,
        service: types.array(types.string),
        specialist: types.array(types.string)
    })

const previousRecordStructure = types
    .model({
        id: types.string,
        date: types.string,
        time: types.string,
        bookingID: types.string,
        editBy: types.string,
        editCount: types.number,
        currentRecord: types.array(recordStructure)
    })

export const previousRecord = types
    .model({
        records: types.array(previousRecordStructure),
        recordCount: types.number
    })
    .actions(self => ({
        restoreRecords(item) {
            self.records.clear()

            item.forEach(data =>
                self.records.push(data)
            )
        },
        addRecord(item) {
            item.forEach(data =>
                self.records.push(data)
            )
        },
        getRecordCount(val) {
            self.recordCount = val
        },
        clear() {
            self.records.clear()
        }
    }))
    .create({
        records: [],
        recordCount: 0
    })

export const editRecord = types
    .model({
        date: types.string,
        time: types.string,
        bookingID: types.string,
        editBy: types.string,
        editCount: types.number,
        currentRecord: types.array(recordStructure)
    })
    .actions(self => ({
        addRecord(data) {

        }
    }))
    .create({
        date: "",
        time: "",
        bookingID: "",
        editBy: "",
        editCount: 0,
        currentRecord: []
    })