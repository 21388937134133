import { types } from 'mobx-state-tree'

export const monthlyState = types
    .model({
        serviceMonth: types.string,
        staffMonth: types.string,
        chargeMonth: types.string,
        pageLoading: types.boolean
    })
    .actions(self => ({
        setServiceMonth(month) {
            self.serviceMonth = month
        },
        setStaffMonth(month) {
            self.staffMonth = month
        },
        setChargeMonth(month) {
            self.chargeMonth = month
        },
        setPageLoading(status) {
            self.pageLoading = status
        }
    }))
    .create({
        serviceMonth: "1月",
        staffMonth: "1月",
        chargeMonth: "1月",
        pageLoading: false
    })