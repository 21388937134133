import { types } from 'mobx-state-tree'
import { withComma, addZero } from 'src/utils'
import { DeleteBooking } from '../API/apis'
import { loginState } from './login'

export const globalStatus = types
    .model({
        bookingLoading: types.boolean,
        allBookingAllClicked: types.boolean,
        historyLoading: types.boolean,
        allBookingFilterDate: types.string
    })
    .actions(self => ({
        setBookingLoading(status) {
            self.bookingLoading = status
        },
        setAllBookingAllClicked() {
            self.allBookingAllClicked = !self.allBookingAllClicked
        },
        setHistoryLoading(status) {
            self.historyLoading = status
        },
        setAllBookingFilterDate(val) {
            self.allBookingFilterDate = val
        }
    }))
    .create({
        bookingLoading: false,
        allBookingAllClicked: false,
        historyLoading: false,
        allBookingFilterDate: ""
    })

export const filterAllBookings = types
    .model({
        packageID: types.string,
        bookingDate: types.string,
        charge: types.string,
        staff: types.string
    })
    .actions(self => ({
        setPackageID(value) {
            self.packageID = value
        },
        setDate(value) {
            self.bookingDate = value
        },
        setCharge(value) {
            self.charge = value
        },
        setStaff(value) {
            self.staff = value
        },
        clear() {
            self.packageID = ""
            self.bookingDate = ""
            self.charge = ""
            self.staff = ""
        }
    }))
    .views(self => ({
        isFiltering() {
            return self.packageID !== "" ||
                self.bookingDate !== "" ||
                self.charge !== "" ||
                self.staff !== ""
        }
    }))
    .create({
        packageID: "",
        bookingDate: "",
        charge: "",
        staff: ""
    })

const bookingDataStructure = types
    .model({
        listID: types.string,
        bookingID: types.string,
        date: types.string,
        packageID: types.string,
        bookingTime: types.string,
        service: types.string,
        charge: types.string,
        specialist: types.string,
        monthCategory: types.string,
        createdBy: types.string,
        createDate: types.string,
        createTime: types.string
    })

export const bookingData = types
    .model({
        bookings: types.array(bookingDataStructure),
        searchedBookings: types.array(bookingDataStructure)
    })
    .actions(self => ({
        updateData(item) {
            self.bookings.clear()
            item.map((x, index) =>
                self.bookings.push({
                    listID: x.id,
                    bookingID: x.bookingID,
                    date: x.date,
                    packageID: `${x.packageID}`,
                    bookingTime: x.bookingTime,
                    service: x.service.map((x) => { return x }).join(", "),
                    charge: `${x.charge}`,
                    specialist: x.specialist.length > 1 ? x.specialist.filter(x => x !== "")
                        .map((x) => { return x })
                        .join(", ") : x?.specialist[0] ?? "",
                    monthCategory: x.monthCategory,
                    createdBy: x.createdBy,
                    createDate: x.createDate,
                    createTime: x.createTime
                })
            )
        },
        updateFiltered(data) {
            self.searchedBookings.clear()
            data.map(x =>
                self.searchedBookings.push({
                    listID: x.id === undefined ? "" : x.id,
                    bookingID: x.bookingID,
                    date: x.date,
                    packageID: x.packageID,
                    bookingTime: x.bookingTime,
                    service: x.service,
                    charge: x.charge,
                    specialist: x.specialist,
                    monthCategory: x.monthCategory,
                    createdBy: x.createdBy,
                    createDate: x.createDate,
                    createTime: x.createTime
                })
            )
            console.log(JSON.stringify(self.searchedBookings))
        },
        clearFilterList() {
            self.searchedBookings.clear()
        },
        removeFromList(list) {
            list.map((list, i) => {
                const index = bookingData.bookings.findIndex(x => x.bookingID === list.bookingID)
                console.log(index)

                bookingData.bookings.splice(index, 1)
                deleteBooking.removeSelected(list.bookingID)
                DeleteBooking(list.listID)
            })
        }
    }))
    .views(self => ({
        bookingSearch(packageID, date, service, charge, specialist) {
            const toFilter = (n, y) => n.includes(y)
            const result = () => {
                return (
                    packageID ? self.bookings.filter(x => x.packageID === packageID) :
                        date ? self.bookings.filter(x => x.date === date) :
                            service ? self.bookings.filter(x => toFilter(x.service, service)) :
                                charge ? self.bookings.filter(x => x.charge === charge) :
                                    specialist ? self.bookings.filter(x => toFilter(x.specialist, specialist)) : []
                )
            }

            self.updateFiltered(result())

            return result()
        },
        allBooingAdvancedFilter(date) {
            const toFilter = (n, y) => n.includes(y)
            const result = () => {
                return (
                    filterAllBookings.packageID ? self.bookings.filter(x => x.packageID === filterAllBookings.packageID) :
                        filterAllBookings.bookingDate || date ? self.bookings.filter(x => x.date === filterAllBookings.bookingDate || x.date === `${date}`) :
                            // service ? self.bookings.filter(x => toFilter(x.service, service)) :
                            filterAllBookings.charge ? self.bookings.filter(x => x.charge === filterAllBookings.charge) :
                                filterAllBookings.staff ? self.bookings.filter(x => toFilter(x.specialist, filterAllBookings.staff)) : self.bookings
                )
            }

            return result()
        },
        allBookingFilter(date) {
            const time = (x) => +x.match(/\d+/g).join("")
            let val = `${date}`

            let bookings = date ?
                self.bookings.filter(x => x.date === val).sort(function (a, b) {
                    const time = (x) => +x.match(/\d+/g).join("")
                    return parseFloat(time(a.bookingTime)) - parseFloat(time(b.bookingTime));
                })
                :
                self.bookings

            return bookings
        },
        findBookingByID(id) {
            const index = self.bookings.findIndex(x => x.bookingID === id)

            return self.bookings[index]
        },
        getBookingType(type) {
            const result = self.bookings.filter(x => x.service.includes(type))

            return result
        },
        getBookingByStaffName(name) {
            const result = self.bookings.filter(x => x.specialist.includes(name))

            return result
        },
        getTotalBookingChargeByMonth(month) {
            const list = self.bookings.filter(x => x.monthCategory === month).map(x => +x.charge)
            let result = list.reduce((i, j) => i + j, 0)

            return result
        },
        getServiceCount() {
            const labels = serviceData.serviceList.map(x => x.serviceName)
            const result = labels.map(x => {
                return {
                    title: x,
                    data: self.getBookingType(x).length
                }
            })

            result.push(self.getOtherServiceCount("all"))

            return result
        },
        getOtherServiceCount(month) {
            const labels = serviceData.serviceList.map(x => x.serviceName)
            const bookings = month === "all" ? bookingData.bookings : bookingData.bookings.filter(x => x.monthCategory === month)
            const bookingServices = bookings
                .filter(x => x.service.includes("加") && !x.service.includes("加毛") || x.service.includes(", ") || x.service.includes(",  "))
                .map(x => x.service)
            const arr = []
            bookingServices.map(x => {
                if (x.includes("加")) {
                    x.split("加").map(c => !labels.includes(c) && !arr.includes(c) && arr.push(c))
                }
                if (x.includes(", ")) {
                    x.split(", ").map(c => !labels.includes(c) && !arr.includes(c) && arr.push(c))
                }
                if (x.includes(",  ")) {
                    x.split(", ").map(c => !labels.includes(c) && !arr.includes(c) && arr.push(c))
                }
            })

            const tidyServiceData = arr.map(x => x.replaceAll(", ", "")).filter(x => x !== "F" && x !== "H" && x !== "毛")
            const serviceCount = tidyServiceData.map(x => {
                return {
                    title: x,
                    count: bookingData.bookings.filter(b => b.service.includes(x)).length
                }
            })
            const combined = {
                title: "其他",
                data: serviceCount.map(x => x.count).reduce((a, b) => a + b, 0)
            }

            return combined
        },
        getMonthlyServiceCount(month) {
            const labels = serviceData.serviceList.map(x => x.serviceName)
            const result = labels.map(x => {
                return {
                    title: x,
                    data: self.getBookingType(x).filter(x => x.monthCategory === month).length
                }
            })

            result.push(self.getOtherServiceCount(month))

            return result
        },
        getStaffCount() {
            const labels = staffData.staffList.map(x => x.staffName)
            const result = labels.map(x => {
                return {
                    title: x,
                    data: self.getBookingByStaffName(x).length
                }
            })

            return result
        },
        getMonthlyStaffCount(month) {
            const labels = staffData.staffList.map(x => x.staffName)
            const result = labels.map(x => {
                return {
                    title: x,
                    data: self.getBookingByStaffName(x).filter(x => x.monthCategory === month).length
                }
            })

            return result
        },
        getYearlyBookingCharge() {
            const months = ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"]
            const result = months.map(x => {
                return {
                    title: x,
                    data: `$ ${withComma(self.getTotalBookingChargeByMonth(x))}`
                }
            })

            return result
        }
    }))
    .create({
        bookings: []
    })

export const createBookingData = types
    .model({
        bookingID: types.string,
        date: types.string,
        packageID: types.string,
        bookingTime: types.string,
        service: types.array(types.string),
        others: types.string,
        charge: types.string,
        specialist: types.array(types.string),
        monthCategory: types.string,
        createdBy: types.string,
        createDate: types.string,
        createTime: types.string,
        createCount: types.number
    })
    .actions(self => ({
        setServices(data) {
            self.service = data
        },
        setSpecialist(data) {
            self.specialist = data
        },
        setOthers(data) {
            self.others = data
        },
        setCount() {
            self.createCount += 1
        },
        getSplicedServiceArray(index) {
            let others = self.others.replaceAll("，", ",")
            self.service.splice(index, 1)

            others.split(", ").map(x => self.appendServiceArray(x))

            return self.service
        },
        appendServiceArray(item) {
            self.service.push(item)
        }
    }))
    .views(self => ({

    }))
    .create({
        bookingID: "",
        date: "",
        packageID: "",
        bookingTime: "",
        service: [],
        others: "",
        charge: "",
        specialist: [],
        monthCategory: "",
        createdBy: "",
        createDate: "",
        createTime: "",
        createCount: 0
    })

export const deleteBooking = types
    .model({
        bookings: types.array(types.string)
    })
    .actions(self => ({
        setBookingID(arr) {
            // self.bookings = arr
            let result = arr.map(x => x.split("_")[0])
            self.bookings = result
        }
    }))
    .views(self => ({
        getBookings() {
            let indexArr = self.bookings.map(x =>
                bookingData.bookings.findIndex(i => i.bookingID === x)
            )

            let idArr = indexArr.map(x =>
                +bookingData.bookings[x].listID
            )
            
            return self.bookings
        }
    }))
    .create({
        bookings: []
    })

export const selectedBooking = types
    .model({
        id: types.array(types.string)
    })
    .actions(self => ({
        setSelected(id) {
            const selectedIndex = self.id.indexOf(id);
            let newSelected = []
            
            if(selectedIndex === -1) {
                self.id.push(id)
            } else {
                self.id.splice(selectedIndex, 1);
            } 
        }
    }))
    .create({
        id: []
    })

const staffStructure = types
    .model({
        staffID: types.number,
        staffName: types.string,
        bookingsCount: types.number
    })

export const staffData = types
    .model({
        staffList: types.array(staffStructure)
    })
    .actions(self => ({
        updateStaffList(data) {
            console.log(JSON.stringify(data))
            self.staffList.clear()
            data.map(x =>
                self.staffList.push({
                    staffID: x.staffID,
                    staffName: x.staffName,
                    bookingsCount: x.bookingsCount
                })
            )
        }
    }))
    .views(self => ({
        getAuthLevel() {

        }
    }))
    .create({
        staffList: []
    })

const serviceStructure = types
    .model({
        serviceID: types.number,
        serviceName: types.string,
        price: types.number
    })

export const serviceData = types
    .model({
        serviceList: types.array(serviceStructure)
    })
    .actions(self => ({
        updateServiceList(data) {
            console.log(data)
            self.serviceList.clear()
            data.map(x =>
                self.serviceList.push({
                    serviceID: x.serviceID,
                    serviceName: x.serviceName,
                    price: x.price
                })
            )
        }
    }))
    .views(self => ({
        getServiceType() {
            let services = []
            let reorg = bookingData.bookings.map(x => {
                if (!(services.includes(x.service))) {
                    return `${x.service}, `
                }
            }).join("")

            services = reorg.split(", ").filter(x => x !== "")

            return services
        }
    }))
    .create({
        serviceList: []
    })

export const generalState = types
    .model({
        searchBookingSearchBody: types.boolean,
        searchBookingSearchFromAppBar: types.boolean
    })
    .actions(self => ({
        setSearchBookingSearchBody(status) {
            self.searchBookingSearchBody = status
        },
        setSearchBookingSearchFromAppBar(status) {
            self.searchBookingSearchFromAppBar = status
        },
    }))
    .create({
        searchBookingSearchBody: true,
        searchBookingSearchFromAppBar: false
    })