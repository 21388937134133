import Moment from 'moment'

export const getDateTime = (type, monthName) => {
    const today = new Date()

    const months = ["January", "Feburary", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
    var result = ""

    if (type === "date") {
        result = monthName ?
            `${months[today.getMonth()]} ${today.getDate()}, ${today.getFullYear()}` :
            today.getFullYear() + '-' + addZero(today.getMonth() + 1) + '-' + addZero(today.getDate())
    } else {
        result = `${addZero(today.getHours())}:${addZero(today.getMinutes())}:${addZero(today.getSeconds())}`
    }

    return result
}

export const getMonthName = (val) => {
    const months = ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"]

    return months[val]
}

export const monthNameToIndex = (val) => {
    const months = ["一月", "二月", "三月", "四月", "五月", "六月", "七月", "八月", "九月", "十月", "十一月", "十二月"]

    return months.findIndex(x => x === val) + 1
}

export const getCurrentMonth = () => {
    let date = new Date()
    const months = ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"]

    return months[date.getMonth()]
}

export const getWeekDay = () => {
    let date = new Date()
    const days = ["日", "一", "二", "三", "四", "五", "六"]

    return days[date.getDate()]
}

export const getWeekDayByDate = (val) => {
    const res = val.match(/\d+/g)
    const days = ["日", "一", "二", "三", "四", "五", "六"]
    const date = `${res[0]}-${res[1]}-${res[2]}`
    let newDate = new Date(date)
    let result = days[newDate.getDay()]

    return result
}

export const getArr = (len) => {
    const arr = [...Array(len).keys()]

    return arr
}

export const getToken = (len) => {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    var charactersLength = characters.length;
    for (var i = 0; i < len; i++) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
}

export const addZero = (val) => {
    return val < 10 ? `0${val}` : val
}

export const ampm = (val) => {
    return val >= 0 && val < 12 ? "am" : "pm"
}

export const timestampToDate = (date) => {
    return new Date(date * 1000).toISOString().split('T')[0]
}

export const dateToTimestamp = (date) => {
    date = date.split("-");
    let newDate = new Date(date[2], date[1] - 1, date[0]);
    console.log(newDate.getTime());
}

export const withComma = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export const randomInt = (max) => {
    return Math.floor(Math.random() * max);
}

export const randomRGBA = () => {
    const colourSet = `${randomInt(255)}, ${randomInt(255)}, ${randomInt(255)}`
    const colour = `rgba(${colourSet}, 0.2)`
    const border = `rgba(${colourSet}, 1)`

    return { colour, border }
}

// export const jsonDecoder = (res) => {
//     let json = res.map(x => x)
//     let result = json.map(x =>
//         JSON.parse(x)    
//     )
//     return result
// }

export const jsonDecoder = (res) => {
    let result = res.map(x => x);
    return result;
}