import react, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Grid } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { getArr, getDateTime, getToken, addZero, ampm, getMonthName, monthNameToIndex, getCurrentMonth, getWeekDay, getWeekDayByDate } from '../utils'
import { createBookingData } from '../Mobx/status';
import { useHistory } from 'react-router-dom/';

import { AddBooking, GetBookings, GetServiceList } from '../API/apis';

import Appbar from './Appbar'
import ServicePicker from './ServicePicker'
import SpecialistPicker from './SpecialistPicker'
import { set } from 'date-fns';
import { loginState } from 'src/Mobx/login';

import { observer } from 'mobx-react-lite';

export default observer(() => {
    const newDate = new Date()

    const history = useHistory()
    const [min, setMin] = useState("");
    const [hour, setHour] = useState("")
    const [packageID, setID] = useState("")
    const [year, setYear] = useState(newDate.getFullYear())
    const [month, setMonth] = useState("")
    const [day, setDay] = useState("")
    const [charge, setCharge] = useState(0)
    const [open, setOpen] = useState(false)
    const [adding, setAdding] = useState(false)
    const [weekDay, setWeekDay] = useState(getWeekDay())

    const HourPicker = () => {
        const handleChange = (event) => {
            setHour(event.target.value);
            event.target.value === 22 && setMin(0)
        };

        let hourRange = getArr(24)

        return (
            <FormControl fullWidth>
                <InputLabel id="hourPickerLabel">時</InputLabel>
                <Select
                    labelId="hourPickerLabel"
                    id="hourPicker"
                    value={hour}
                    label="Age"
                    onChange={handleChange}
                >
                    {hourRange.map((x, i) =>
                        i > 7 && i < 22 && <MenuItem value={i + 1}>{i + 1}</MenuItem>
                    )}
                </Select>
            </FormControl>
        );
    }

    const MinutePicker = () => {

        const handleChange = (event) => {
            setMin(event.target.value);
        };

        let minRange = getArr(60)

        return (
            <FormControl fullWidth>
                <InputLabel id="hourPickerLabel">分</InputLabel>
                <Select
                    labelId="hourPickerLabel"
                    id="hourPicker"
                    value={min}
                    label="Age"
                    onChange={handleChange}
                >
                    <MenuItem value={0}>00</MenuItem>
                    {hour !== 22 &&
                        minRange.map((x, i) =>
                            (i + 1) % 5 === 0 && i + 1 !== 60 && <MenuItem value={i + 1}>{addZero(i + 1)}</MenuItem>
                        )
                    }
                </Select>
            </FormControl>
        );
    }

    const Timepicker = () => {
        return (
            <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center">
                <Grid item xs={6}>
                    <HourPicker />
                </Grid>
                <Grid item xs={6}>
                    <MinutePicker />
                </Grid>
            </Grid>
        )
    }

    const MonthPicker = () => {
        const handleChange = (event) => {
            setMonth(event.target.value);
            newDate.getMonth() + 1 === 12 && event.target.value < 12 ? setYear(newDate.getFullYear() + 1) : setYear(newDate.getFullYear())
        };

        let monthRange = getArr(12)
        let currentMonth = newDate.getMonth()

        const getMonthIndexs = () => {
            let current = currentMonth + 1;
            let res = {
                current: current,
                next: undefined,
                nextAfter: undefined
            }

            if(current + 1 > 12) {
                res.next = (current + 1) - 12;
            } else {
                res.next = current + 1;
            }

            if(current + 2 > 12) {
                res.nextAfter = (current + 2) - 12;
            } else {
                res.nextAfter = current + 2;
            }

            res = {
                current: res.current - 1,
                next: res.next - 1,
                nextAfter: res.nextAfter - 1,
            }

            return res;
        }

        return (
            <FormControl fullWidth>
                <InputLabel id="hourPickerLabel">月</InputLabel>
                <Select
                    labelId="hourPickerLabel"
                    id="hourPicker"
                    value={month}
                    label="月"
                    onChange={handleChange}
                >
                    {/* {monthRange.map((x, i) =>
                        i <= (newDate.getMonth() + 1) + 1 && i >= (newDate.getMonth() + 1) - 1 &&
                        <MenuItem value={i + 1} key={i}>{getMonthName(i)}</MenuItem>
                    )} */}
                    {monthRange.map((x, i) =>
                        <MenuItem 
                            value={i + 1} 
                            key={i} 
                            disabled={!(i === getMonthIndexs().current || i === getMonthIndexs().next || i === getMonthIndexs().nextAfter)}
                        >
                            {getMonthName(i)}
                        </MenuItem>
                    )}
                </Select>
            </FormControl>
        );
    }

    const DayPicker = () => {

        const handleChange = (event) => {
            setDay(event.target.value);
            setWeekDay(getWeekDayByDate(`2023-${month}-${day}`))
        };

        const daysInMonth = [31, 28, 30, 31, 30, 31, 30, 31, 30, 31, 30, 31]
        const now = new Date()
        const date = now.getDate()
        const currentmonth = now.getMonth() + 1

        let daysRange = getArr(daysInMonth[month - 1])

        return (
            <FormControl fullWidth>
                <InputLabel id="hourPickerLabel">日</InputLabel>
                <Select
                    labelId="hourPickerLabel"
                    id="hourPicker"
                    value={day}
                    disabled={month === ""}
                    label="日"
                    onChange={handleChange}
                >
                    {/* {daysRange.map((x, i) =>
                        i >= newDate.getDate() - 1 && <MenuItem value={i + 1}>{i + 1}</MenuItem>
                    )} */}
                    {daysRange.map((x, i) =>
                        // i + 1 < date && month == currentmonth ? null :
                        <MenuItem value={i + 1} key={i}>
                            {`${i + 1}號`}
                        </MenuItem>
                    )}
                </Select>
            </FormControl>
        );
    }

    const BookingDatePicker = () => {
        return (
            <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center">
                <Grid item xs={2} lg={4}>
                <TextField
                        id="outlined-required"
                        label="年"
                        value={year}
                        disabled
                        InputProps={{
                            readOnly: true
                        }}
                        style={{ width: "100%", position: 'relative', right: 5, textAlignLast: 'center' }}
                    />
                </Grid>
                <Grid item xs={5} lg={4}>
                    <MonthPicker />
                </Grid>
                <Grid item xs={5} lg={4}>
                    <DayPicker />
                </Grid>
                {/* <Grid item xs={1} lg={4} display={{ xs: "none", lg: "block" }}>
                    <TextField
                        id="outlined-required"
                        label="星期"
                        value={weekDay}
                        InputProps={{
                            readOnly: true
                        }}
                        style={{ width: "100%", position: 'relative', right: 5, textAlignLast: 'center' }}
                    />
                </Grid> */}
            </Grid>
        )
    }

    const createBooking = () => {
        const date = newDate

        setAdding(true)

        const months = ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"]
        let serviceIndex = createBookingData.service.findIndex(x => x === "其他")
        // console.log(serviceIndex)
        let serviceList = undefined

        if (serviceIndex === -1) {
            serviceList = createBookingData.service
        } else {
            serviceList = createBookingData.getSplicedServiceArray(serviceIndex)
        }

        let data = {
            bookingID: getToken(25),
            date: `${year}-${addZero(month)}-${addZero(day)}`,
            packageID: packageID,
            bookingTime: `${addZero(hour)}:${addZero(min)} ${ampm(hour)}`,
            weekDay: getWeekDayByDate(`${year}-${month}-${day}`),
            service: serviceList,
            charge: charge,
            specialist: createBookingData.specialist,
            monthCategory: months[month - 1],
            yearCategory: date.getFullYear(),
            createdBy: loginState.user,
            createDate: getDateTime("date"),
            createTime: getDateTime("time")
        }


        AddBooking(data)
            .then(() => {
                createBookingData.setCount()

                setMonth("")
                setDay("")
                setID("")
                setHour("")
                setMin("")
                setCharge("")

                setOpen(true)

                history.push("/home")

                setAdding(false)
            })
    }

    const shoudDisable = () => {
        let condiSt = month === "" || day === "" || packageID == "" || min === ""
        let condiSec = createBookingData.service.length === 0 || createBookingData.specialist.length === 0 || charge === 1

        let result = condiSt || condiSec

        return result
    }

    const CreatedMessage = () => {

        const handleClose = (event, reason) => {
            if (reason === 'clickaway') {
                return;
            }

            setOpen(false);
        };

        const action = (
            <>
                <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={handleClose}
                >
                    <CloseIcon fontSize="small" />
                </IconButton>
            </>
        );

        return (
            <div>
                <Snackbar
                    style={{ background: "#F6E4E6" }}
                    open={open}
                    autoHideDuration={1000000}
                    onClose={handleClose}
                    message="成功建立預約"
                    action={action}
                />
            </div>
        );
    }

    return (
        <div style={{ height: '100vh' }}>
            <Appbar />
            <CreatedMessage />
            <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center" style={{ textAlign: 'center' }}>
                <Grid item xs={10} style={{ marginTop: 20 }}>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 1, width: '25ch' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <Grid container direction="row" justifyContent="center" alignItems="center">
                            <Grid item xs={12} style={{ position: 'relative', right: 6 }}>
                                <TextField
                                    error={false}
                                    id="outlined-error"
                                    label="套票號碼"
                                    placeholder="3962, 阿蚊, 605朋友"
                                    style={{ width: '100%' }}
                                    onChange={(e) => setID(e.target.value)}
                                />
                            </Grid>
                        </Grid>
                        <div style={{ marginTop: 30, marginBottom: 30 }}>
                            <BookingDatePicker />
                        </div>
                        <div style={{ marginTop: 30, marginBottom: 30 }}>
                            <Timepicker />
                        </div>
                        <div>
                            <ServicePicker />
                        </div>
                        <TextField
                            error={false}
                            id="outlined-error"
                            label="價錢"
                            placeholder="800"
                            style={{ width: '100%', position: 'relative', right: 6, marginTop: 30 }}
                            type="number"
                            onChange={(e) => setCharge(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        $
                                    </InputAdornment>
                                )
                            }}
                        />
                        <div style={{ marginTop: 20 }}>
                            <SpecialistPicker />
                        </div>
                        <div style={{ marginTop: 20, marginBottom: 40 }}>
                            <Button
                                variant="contained"
                                size="large"
                                style={{ width: '100%', marginTop: 25, background: packageID == "" || month == "" || day == "" ? '#eee' : '#EDC2BD' }}
                                onClick={() => createBooking()}
                                disabled={packageID == "" || month == "" || day == "" || adding}
                            // onClick={() => setOpen(true)}
                            // disabled={shoudDisable()}
                            >
                                {adding ? <CircularProgress style={{ color: '#fff' }} size={25} /> : "繼續"}
                            </Button>
                        </div>
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
})