import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import ReportIcon from '@mui/icons-material/Assessment';
import BookingIcon from '@mui/icons-material/AccessTimeFilled';
import CreateBookingIcon from '@mui/icons-material/AddCircle';
import SearchBookingIcon from '@mui/icons-material/Search';

import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import ManagementIcon from '@mui/icons-material/ManageSearch';

import { useHistory } from 'react-router-dom';
import Logout from '@mui/icons-material/Logout';
import { loginState } from '../../Mobx/login';
import { bookingData, generalState } from 'src/Mobx/status';

export default () => {
    let history = useHistory()
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const logout = () => {
        history.push("/")
        loginState.setLogin(false)
        console.log(loginState.isLogedIn)
    }

    const iconColor = "#333"

    const listItemArray = {
        topList: [
            { title: "所有預約", icon: <BookingIcon style={{ color: iconColor }} />, authLevel: 1, actions: () => history.push("home") },
            { title: "建立預約", icon: <CreateBookingIcon style={{ color: iconColor }} />, authLevel: 1, actions: () => history.push("createBooking") },
            {
                title: "搜尋預約", icon: <SearchBookingIcon style={{ color: iconColor }} />, authLevel: 1, actions: () => {
                    bookingData.clearFilterList()
                    generalState.setSearchBookingSearchBody(true)
                    history.push("/searchBooking")
                }
            },
            { title: "總結報告", icon: <ReportIcon style={{ color: iconColor }} />, authLevel: 3, actions: () => history.push("reports") },
            // { title: "後台管理", icon: <ManagementIcon style={{ color: iconColor }} />, authLevel: 3, actions: () => history.push("management") }
        ],
        bottomList: [
            // { title: "設定", icon: <SettingsIcon style={{ color: iconColor }} />, authLevel: 1, actions: () => history.push("settings") },
            { title: "登出", icon: <LogoutIcon style={{ color: iconColor }} />, authLevel: 1, actions: logout }
        ]
    }

    const list = (anchor) => (
        <Box
            sx={250}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>
                {listItemArray.topList.map((x, index) => (
                    loginState.authLevel >= x.authLevel &&
                    <ListItem button key={index} onClick={x.actions}>
                        <ListItemIcon>
                            {x.icon}
                        </ListItemIcon>
                        <ListItemText primary={x.title} />
                    </ListItem>
                ))}
            </List>
            <Divider />
            <List>
                {listItemArray.bottomList.map((x, index) => (
                    loginState.authLevel >= x.authLevel &&
                    <ListItem button key={x} onClick={x.actions}>
                        <ListItemIcon>
                            {x.icon}
                        </ListItemIcon>
                        <ListItemText primary={x.title} />
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    return (
        <div>
            <React.Fragment key={"left"}>
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    sx={{ mr: 2 }}
                    onClick={toggleDrawer("left", true)}
                >
                    <MenuIcon />
                </IconButton>
                <Drawer
                    anchor={"left"}
                    open={state["left"]}
                    onClose={toggleDrawer("left", false)}
                >
                    {list("left")}
                </Drawer>
            </React.Fragment>
        </div>
    );
}
