import react, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CircularProgress from '@mui/material/CircularProgress';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Grid from '@mui/material/Grid';
import Collapse from '@mui/material/Collapse';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { loginState } from '../../Mobx/login';

import LoginBackgroundImg from '../../hero.JPG'

import { getDateTime } from '../../utils';


import { Login } from '../../API/apis';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import { observer } from 'mobx-react-lite';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const CollapseContent = () => {
    return (
        <>
            <Typography paragraph>{getDateTime("date", true)}</Typography>
        </>
    )
}

export default observer(() => {
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    let history = useHistory()

    useEffect(() => {
        loginState.isLogedIn ? history.push("/home") : history.push("/")

    }, [loginState.isLogedIn])

    const LoginCard = () => {
        const [expanded, setExpanded] = useState(false);
        const [username, setUsername] = useState("admin");
        const [password, setPassword] = useState("664d1#$B");
        const [logging, setLogging] = useState(false)

        const handleExpandClick = () => {
            setExpanded(!expanded);
        };

        return (
            <Card sx={{ maxWidth: 345 }}>
                <CardMedia
                    component="img"
                    height="194"
                    image={LoginBackgroundImg}
                    alt="Paella dish"
                />
                <CardContent>
                    <TextField
                        label="用戶名稱"
                        variant="outlined" disabled={logging}
                        onChange={(e) => setUsername(e.target.value)} value={username}
                        style={{ width: '100%', marginBottom: 25 }}
                        error={loginState.loginFailed}
                    />
                    <TextField
                        label="密碼"
                        variant="outlined"
                        style={{ width: '100%' }}
                        type="password" disabled={logging}
                        onChange={(e) => setPassword(e.target.value)} value={password}
                        error={loginState.loginFailed}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                setLogging(true)
                                Login({ username: username.toLowerCase(), password: password })
                                    .then(() => {
                                        setLogging(false)
                                    })
                            }
                        }}
                    />
                    <Button
                        variant="contained" disabled={logging}
                        style={{ width: '100%', marginTop: 25, background: logging ? '#DBDBDB' : '#EDC2BD' }}
                        onClick={() => {
                            setLogging(true)
                            Login({ username: username.toLowerCase(), password: password })
                                .then(() => {
                                    setLogging(false)
                                })
                        }}
                    >
                        {logging ? <CircularProgress style={{ color: 'white' }} size={25} /> : "登入"}
                    </Button>
                </CardContent>
                <CardActions disableSpacing>
                    <IconButton aria-label="add to favorites">
                        <FavoriteIcon />
                    </IconButton>
                    <IconButton aria-label="share">
                        <ShareIcon />
                    </IconButton>
                    <ExpandMore
                        expand={expanded}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <ExpandMoreIcon />
                    </ExpandMore>
                </CardActions>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent>
                        <CollapseContent />
                    </CardContent>
                </Collapse>
            </Card>
        );
    }

    return (
        <div style={{ height: "100vh", padding: 15 }}>
            <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center" style={{ textAlign: 'center' }}>
                <Grid item xs={10} style={{ marginTop: 150 }}>
                    <LoginCard />
                </Grid>
            </Grid>
        </div>
    );
})
