import { types } from 'mobx-state-tree'
import { getDateTime, getToken } from '../utils'

export const loginState = types
    .model({
        isLogedIn: types.boolean,
        user: types.string,
        userID: types.number,
        authLevel: types.number,
        date: types.string,
        time: types.string,
        token: types.string,
        loginFailed: types.boolean
    })
    .actions(self => ({
        setLogin(status) {
            self.isLogedIn = status
        },
        toLogin(username, authLevel) {
            self.user = username
            self.authLevel = authLevel
            self.date = getDateTime("date")
            self.time = getDateTime("time")
            self.token = getToken()
        },
        toLogout() {
            self.isLogedIn = false
            self.user = ""
            self.userID = 0
            self.date = ""
            self.time = ""
            self.token = ""
        },
        failToLogin(status) {
            self.loginFailed = status
        }
    }))
    .views(self => ({
        checkLoginStatus() {
            return self.isLogedIn
        }
    }))
    .create({
        isLogedIn: false,
        user: "",
        userID: 0,
        authLevel: 0,
        date: "",
        time: "",
        token: "",
        loginFailed: false
    })