import react, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';

import { createBookingData, staffData } from '../Mobx/status';

import { GetStaffList } from 'src/API/apis';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, specialistName, theme) {
    return {
        fontWeight:
            specialistName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export default () => {
    const theme = useTheme();
    const [name, setName] = useState([]);

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
        createBookingData.setSpecialist(value)
    };

    // useEffect(() => {
    //     GetStaffList()
    // }, [])

    const option = [
        { staffID: 100, staffName: "Polly", bookingsCount: 0 },
        { staffID: 101, staffName: "菁菁", bookingsCount: 0 },
        { staffID: 102, staffName: "Koey", bookingsCount: 0 },
        { staffID: 103, staffName: "Barbara", bookingsCount: 0 },
        { staffID: 104, staffName: "Ci", bookingsCount: 0 }
    ]

    return (
        <div>
            <FormControl sx={{ m: 1, width: '100%', position: 'relative', right: 6 }}>
                <InputLabel id="demo-multiple-chip-label">負責員工</InputLabel>
                <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    style={{ width: '100%' }}
                    value={name}
                    onChange={handleChange}
                    input={<OutlinedInput id="select-multiple-chip" label="負責員工" />}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                                <Chip key={value} label={value} />
                            ))}
                        </Box>
                    )}
                    MenuProps={MenuProps}
                >
                    {option.map(x => x.staffName).map((x) => (
                        <MenuItem
                            key={x}
                            value={x}
                            style={getStyles(x, name, theme)}
                        >
                            {x}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}
