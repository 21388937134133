import react, { useState, useEffect } from 'react';
import { Line, Pie, Bar } from 'react-chartjs-2';
import { observer } from 'mobx-react-lite';
import Appbar from './Appbar'

import { bookingData, serviceData, staffData } from '../Mobx/status';
import { monthlyState } from '../Mobx/reportState';
import { randomRGBA } from '../utils';

import {
  Grid, Divider, InputLabel, MenuItem, FormControl, Select, Box, TextField,
  Accordion, AccordionSummary, AccordionDetails, Typography, CircularProgress
} from '@mui/material';

import {
  ExpandMore
} from '@mui/icons-material';

import { GetBookings, GetServiceList } from '../API/apis';
import { loginState } from 'src/Mobx/login';
import { useHistory } from 'react-router-dom';

const getColours = (index, opacity) => {
  let list = [
    `rgba(255, 99, 132, ${opacity})`,
    `rgba(255, 159, 64, ${opacity})`,
    `rgba(22, 65, 25, ${opacity})`,
    `rgba(160, 174, 192, ${opacity})`,
    `rgba(255, 155, 192, ${opacity})`,
    `rgba(255, 205, 86, ${opacity})`,
    `rgba(75, 192, 192, ${opacity})`,
    `rgba(54, 162, 235, ${opacity})`,
    `rgba(153, 102, 255, ${opacity})`,
    `rgba(100, 193, 135, ${opacity})`,
    `rgba(201, 203, 207, ${opacity})`,
    `rgba(102, 102, 102, ${opacity})`,
    `rgba(209, 64, 64, ${opacity})`,
    `rgba(181, 146, 240, ${opacity})`
  ]

  return list[index]
}

const AccordionTabs = (title, panels) => {
  const [expanded, setExpanded] = useState(true);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      <Accordion expanded={expanded} onChange={handleChange(`panel`)} style={{ marginLeft: 10, marginRight: 10 }}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls={`panel-bh-content`}
          id={`panel-bh-header`}
          onClick={() => setExpanded(!expanded)}
        >
          <Typography sx={{ flexShrink: 0 }}>
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ minHeight: '450px' }}>
          <Grid container spacing={3}>
            {panels.map((x, i) =>
              <Grid item xs={12} key={i}>
                {x}
              </Grid>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

const DataForm = (data) => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <Divider />
      <Accordion expanded={expanded} onChange={handleChange(`panel`)} style={{ marginTop: 25 }}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls={`panel-bh-content`}
          id={`panel-bh-header`}
          onClick={() => setExpanded(!expanded)}
        >
          <Typography sx={{ flexShrink: 0 }}>
            項目總計
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center" style={{ marginTop: 5, marginBottom: 25 }}>
            {data.map((x, i) =>
              <Grid item xs={4}>
                {/* <Typography style={{ textAlign: 'center' }}>
                  {x.title}: {x.data}
                </Typography> */}
                <TextField
                  label={x.title} variant="outlined"
                  value={x.data} style={{ textAlign: 'center' }}
                  size="small"
                />
              </Grid>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  )
}


const barChart = (labels, data, title) => {
  const datas = {
    labels: labels,
    datasets: [{
      label: title,
      data: data,
      backgroundColor: data.map((x, i) => getColours(i, 0.4)),
      borderColor: data.map((x, i) => getColours(i, 1)),
      borderWidth: 1
    }]
  };

  return (
    <Bar
      data={datas}
    />
  )
}


export default observer(() => {
  let history = useHistory()
  const date = new Date();
  const lastYear = date.getFullYear() - 1

  useEffect(() => {
    if (loginState) {
      GetBookings()
    }
    else {
      history.push("/")
    }

  }, [])

  let serviceMonth = monthlyState.serviceMonth
  let staffMonth = monthlyState.staffMonth
  let chargeMonth = monthlyState.chargeMonth


  const GetServiceCount = () => {
    const labels = serviceData.serviceList.map(x => x.serviceName)
    const data = labels.map(x => bookingData.getBookingType(x).length)

    labels.push(
      bookingData.getOtherServiceCount("all").title
    )
    data.push(
      bookingData.getOtherServiceCount("all").data
    )

    return (
      barChart(labels, data, "預約服務總數 (" + lastYear + ")")
    )
  }

  const GetStaffBookingCount = () => {
    const labels = staffData.staffList.map(x => x.staffName)
    const data = labels.map(la =>
      bookingData.bookings.filter(x => x.specialist.includes(la)).length
    )

    return (
      barChart(labels, data, "個人服務預約總數 (" + lastYear + ")")
    )
  }

  const GetYearlyBookingCharge = () => {
    const labels = ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"]
    const data = labels.map(x => bookingData.getTotalBookingChargeByMonth(x))

    return (
      barChart(labels, data, "全年收入統計 (" + lastYear + ")")
    )
  }

  const GetServiceCountMonthly = () => {
    const months = ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"]

    const labels = serviceData.serviceList.map(x => x.serviceName)
    const data = labels.map(x => bookingData.getBookingType(x).filter(m => m.monthCategory === serviceMonth).length)

    labels.push(
      bookingData.getOtherServiceCount(monthlyState.serviceMonth).title
    )
    // console.log(bookingData.getOtherServiceCount(monthlyState.serviceMonth).title)
    data.push(
      bookingData.getOtherServiceCount(monthlyState.serviceMonth).data
    )

    return (
      <>
        {barChart(labels, data, `預約服務總數 (${serviceMonth})`)}
        <Box style={{ marginTop: 15, marginBottom: 15, marginLeft: 10, marginRight: 10 }}>
          <FormControl fullWidth>
            <InputLabel id="monthPicker">月份</InputLabel>
            <Select
              labelId="monthPicker"
              id="demo-simple-select"
              value={serviceMonth}
              label="月份"
              onChange={(e) => monthlyState.setServiceMonth(e.target.value)}
            >
              {months.map((x, i) =>
                <MenuItem value={x} key={i}>{x}</MenuItem>
              )}
            </Select>
          </FormControl>
        </Box>
      </>
    )
  }

  const GetStaffBookingCountMonthly = () => {
    const months = ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"]

    const labels = staffData.staffList.map(x => x.staffName)
    const data = labels.map(x => bookingData.getBookingByStaffName(x).filter(m => m.monthCategory === staffMonth).length)
    // console.log(JSON.stringify(data))

    return (
      <>
        {barChart(labels, data, `個人服務預約總數 (${staffMonth})`)}
        <Box style={{ marginTop: 15, marginBottom: 15, marginLeft: 10, marginRight: 10 }}>
          <FormControl fullWidth>
            <InputLabel id="monthPicker">月份</InputLabel>
            <Select
              labelId="monthPicker"
              id="demo-simple-select"
              value={staffMonth}
              label="月份"
              onChange={(e) => monthlyState.setStaffMonth(e.target.value)}
            >
              {months.map((x, i) =>
                <MenuItem value={x} key={i}>{x}</MenuItem>
              )}
            </Select>
          </FormControl>
        </Box>
      </>
    )
  }

  const GetMonthlyBookingCharge = () => {
    const months = ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"]

    const labels = serviceData.serviceList.map(x => x.serviceName)
    const data = labels.map(x => bookingData.getBookingType(x).filter(m => m.monthCategory === chargeMonth).map(x => +x.charge).reduce((i, j) => i + j, 0))
    // console.log(JSON.stringify(data))

    return (
      <>
        {barChart(labels, data, `個人服務預約總數 (${chargeMonth})`)}
        <Box style={{ marginTop: 15, marginBottom: 15, marginLeft: 10, marginRight: 10 }}>
          <FormControl fullWidth>
            <InputLabel id="monthPicker">月份</InputLabel>
            <Select
              labelId="monthPicker"
              id="demo-simple-select"
              value={chargeMonth}
              label="月份"
              onChange={(e) => monthlyState.setChargeMonth(e.target.value)}
            >
              {months.map((x, i) =>
                <MenuItem value={x} key={i}>{x}</MenuItem>
              )}
            </Select>
          </FormControl>
        </Box>
      </>
    )
  }

  return (
    <>
      <Appbar />

      <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center" style={{ textAlign: 'center', marginTop: 10, marginBottom: 45, padding: '15px 5%' }}>

        {monthlyState.pageLoading ?
          <CircularProgress style={{ color: '#EDC2BD' }} />
          :
          <>
            <Grid item xs={4}>
              {AccordionTabs(
                "每月預約服務總數",
                [
                  <GetServiceCountMonthly />,
                  DataForm(bookingData.getMonthlyServiceCount(monthlyState.serviceMonth))
                ]
              )}
            </Grid>
            {/* <Grid item xs={4}>
              {AccordionTabs(
                "每月個人服務預約總數",
                [
                  <GetStaffBookingCountMonthly />,
                  DataForm(bookingData.getMonthlyStaffCount(monthlyState.staffMonth))
                ]
              )}
            </Grid> */}
            <Grid item xs={4}>
              {AccordionTabs(
                "預約服務總數 (" + lastYear + ")",
                [
                  <GetServiceCount />,
                  DataForm(bookingData.getServiceCount())
                ]
              )}
            </Grid>
            {/* <Grid item xs={4}>
              {AccordionTabs(
                "個人服務預約總數 (" + lastYear + ")",
                [
                  <GetStaffBookingCount />,
                  DataForm(bookingData.getStaffCount())
                ]
              )}
            </Grid> */}
            <Grid item xs={4}>
              {AccordionTabs(
                "全年收入統1計 (" + lastYear + ")",
                [
                  <GetYearlyBookingCharge />,
                  DataForm(bookingData.getYearlyBookingCharge())
                ]
              )}
            </Grid>
            <Grid item xs={4}></Grid>
            {/* <Grid item xs={4}>
              {AccordionTabs(
                "每月收入統計",
                [
                  <GetMonthlyBookingCharge />,
                  DataForm(bookingData.getStaffCount())
                ]
              )}
            </Grid> */}
          </>
        }


      </Grid>
    </>
  )
})
