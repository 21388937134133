import react, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material'

import { GetServiceList } from '../API/apis';
import { servicePicker } from '../Mobx/editState'
import { createBookingData, serviceData } from '../Mobx/status';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, serviceName, theme) {
    return {
        fontWeight:
            serviceName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const ServicePicker = () => {
    const [value, setValue] = useState(null)
    const [inputValue, setInputValue] = useState('')

    return (
        <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={servicePicker.getFilteredList(serviceData.serviceList)}
            filterOptions={(options, state) => options}
            getOptionLabel={(option) => option.serviceName}
            value={value}
            sx={{ width: "auto" }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue)
            }}
            renderInput={(params) => <TextField {...params} label="服務" />}
            onKeyPress={(e) => {
                if (e.key === 'Enter') {
                    servicePicker.addService(inputValue)
                    setInputValue("")
                }
            }}
            ListboxProps={{ style: { maxHeight: 170 } }}
        />
    );
}

export default (defaultValue) => {
    const theme = useTheme()
    const [serviceName, setServiceName] = useState([]);

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;

        createBookingData.setServices(value)

        setServiceName(
            typeof value === 'string' ? value.split(',') : value,
        );

    };


    // useEffect(() => {
    //     GetServiceList()
    // }, [])

    const options = [
        {
            "serviceID": 2001,
            "serviceName": "HF",
            "price": 0
        },
        {
            "serviceID": 2002,
            "serviceName": "Gel H",
            "price": 0
        },
        {
            "serviceID": 2003,
            "serviceName": "Gel F",
            "price": 0
        },
        {
            "serviceID": 2004,
            "serviceName": "毛",
            "price": 0
        },
        {
            "serviceID": 2005,
            "serviceName": "Facial",
            "price": 0
        },
        {
            "serviceID": 2006,
            "serviceName": "彩光",
            "price": 0
        },
        {
            "serviceID": 2007,
            "serviceName": "修眉",
            "price": 0
        },
        {
            "serviceID": 2008,
            "serviceName": "補眉",
            "price": 0
        },
        {
            "serviceID": 2009,
            "serviceName": "加毛",
            "price": 0
        },
        {
            "serviceID": 2010,
            "serviceName": "眼線",
            "price": 0
        },
        {
            "serviceID": 2000,
            "serviceName": "其他",
            "price": 0
        }
    ]

    return (
        <div>
            <FormControl sx={{ m: 1, width: '100%', position: 'relative', right: 6 }}>
                <InputLabel id="demo-multiple-chip-label">服務</InputLabel>
                <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    style={{ width: '100%' }}
                    value={serviceName}
                    onChange={handleChange}
                    input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                    renderValue={(selected) => (
                        <Box>
                            {selected.map((value) => (
                                <Chip key={value} label={value} />
                            ))}
                        </Box>
                    )}
                    MenuProps={MenuProps}
                >
                    {options.map(x => x.serviceName).map((x) => (
                        <MenuItem
                            key={x}
                            value={x}
                            style={getStyles(x, serviceName, theme)}
                        >
                            {x}
                        </MenuItem>
                    ))}
                </Select>

                {/* <ServicePicker /> */}

            </FormControl>
            {createBookingData.service.includes("其他") &&
                <TextField
                    label="其他"
                    variant="standard"
                    onChange={(e) => createBookingData.setOthers(e.target.value)}
                    style={{ width: '100%', marginTop: 10, marginBottom: 20, position: 'relative', right: 5 }}
                />
            }
        </div>
    );
}
