import react, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Appbar from './Appbar'
import { bookingData, globalStatus, createBookingData } from '../Mobx/status'
import { GetBookings, GetStaffList, GetServiceList } from '../API/apis'
import { Paper } from '@material-ui/core'
import TodaysBooking from './TodaysBooking'
import AllBookings from './AllBooking'

import { CircularProgress } from '@mui/material';

import { observer } from 'mobx-react-lite'
import { loginState } from 'src/Mobx/login';
import { useHistory } from 'react-router-dom';


export default observer(() => {
    let history = useHistory()

    useEffect(() => {
        console.log(loginState.isLogedIn)
        if (loginState) {
            GetBookings()
            GetStaffList()
            GetServiceList()
        } else {
            history.push("/")
        }

    }, [loginState.isLogedIn, createBookingData.createCount])

    return (
        <div style={{ height: '100vh', marginBottom: 25 }}>
            <Appbar />
            {globalStatus.bookingLoading ?
                <div style={{ height: '100vh', textAlign: 'center' }}>
                    <span style={{ position: 'relative', top: 250 }}>
                        <CircularProgress style={{ color: '#EDC2BD' }} />
                    </span>
                </div>
                :
                <div style={{ marginTop: 10, marginBottom: 25 }}>
                    <div style={{ marginTop: 15, marginBottom: 35, marginLeft: 5, marginRight: 5 }}>
                        <Paper>
                            <TodaysBooking />
                        </Paper>
                    </div>

                    <div style={{ marginTop: 35, marginBottom: 35, marginLeft: 5, marginRight: 5 }}>
                        <Paper>
                            <AllBookings />
                        </Paper>
                    </div>


                </div>
            }

        </div>
    );
})
